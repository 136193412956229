<template>
  <div class="default-modal terms-modal" :class="{ 'terms-modal--center': center }">
    <div class="default-modal__header terms-modal__header">
      <h3 class="default-modal__title">Обработка персональных данных</h3>
    </div>
    <div class="terms-modal__body">
      <div class="terms-modal__message">
        Я свободно, своей волей и в своем интересе даю свое согласие на обработку следующих персональных
        данных как без использования средств автоматизации, так и с их использованием: фамилия, имя, отчество,
        пол, номер телефона, адрес электронной почты, дата рождения, данные о совершенных покупках, Обществом
        с ограниченной ответственностью «ГАЛАСТОР», адрес: 119034, Москва, ул. Пречистенка, д. 40/2, стр. 1,
        этаж 3, помещ. XXIII, для целей: участия в программе лояльности ГАЛАМАРТ, маркетинговых акциях,
        опросах, исследованиях, следующими способами: сбор, запись, систематизацию, накопление, хранение,
        уточнение (обновление, изменение), извлечение, использование, передачу (предоставление),
        обезличивание, блокирование, удаление, уничтожение персональных данных. <br />
        Я подтверждаю, что ознакомлен(а) с перечнем партнёров ООО «ГАЛАСТОР», расположенным по адресу:
        <a href="https://galamart.ru/loyality/partners/" target="_blank">https://galamart.ru/loyality/partners/</a>, которым я
        разрешаю передачу моих персональных данных, указанных выше, и/или поручение их обработки. <br />
        Я согласен(а) с тем, что указанный перечень партнёров ООО «ГАЛАСТОР» может быть изменён без изменения
        целей, для которых дано настоящее согласие, в связи с чем ООО «ГАЛАСТОР» внесет исправления в
        перечень, расположенный по адресу:
        <a href="https://galamart.ru/loyality/partners/" target="_blank">https://galamart.ru/loyality/partners/</a>. Я
        обязуюсь с разумной периодичностью проверять исправления в указанном перечне. <br />
        Согласие выдано бессрочно, я проинформирован о праве отзыва согласия путем направления заявления на юридический
        адрес ООО «ГАЛАСТОР».
      </div>
    </div>
    <div class="default-modal__footer terms-modal__footer">
      <button type="button" class="btn-main" @click="confirm">
        Принять
      </button>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/components/svg/CloseIcon.vue";

export default {
  name: "TermsModal",
  props: {
    callback: Function,
    center: Boolean
  },
  methods: {
    confirm() {
      this.$emit("close");
      if (this.callback) {
        this.callback();
      }
    },
  },
  components: { CloseIcon },
};
</script>

<style lang="stylus">
.terms-modal {
  width 100%
  max-width 800px

  &--center &__header {
    justify-content center
  }

  &__body {
    display flex
    flex-direction column
    width 100%
    padding: 15px 30px 30px 30px
  }

  &__message {
    width 100%
    font-size 0.875rem

    a {
      color var(--main_color)
      text-decoration underline
    }
  }
}
</style>
