<template>
  <form @submit.prevent="formSubmit" class="form" novalidate spellcheck="false">
    <div class="form__item">
      <InputComponent
          v-model="form.confirmCode"
          title="Код"
          :mask="vMaskStr"
          :disabled="loading"
      />
    </div>
    <div class="form__message" v-if="phoneMessage" v-html="phoneMessage"></div>
    <div class="form__message">Введите код подтверждения из смс</div>
<!--    <div v-if="timer > 0" class="form__message">Повторная отправка кода будет доступна через: {{ timer }} секунд</div>-->
    <div class="form__error" v-if="error">{{ error }}</div>
    <div class="form__error" v-if="codeError">{{ codeError }}</div>
<!--    <div v-if="!codeError && timer <= 0" class="form__actions">-->
<!--      <button @click.prevent="resendCode" class="btn-main btn-auto" type="button" :disabled="timer > 0">-->
<!--        <template>Отправить код еще раз</template>-->
<!--      </button>-->
<!--    </div>-->
    <div class="form__actions">
      <button
          type="button"
          @click.prevent="$emit('changeStep', codes.BEGIN_REGISTRATION_CODE)"
          class="btn-main"
      >
        Назад
      </button>
      <button class="btn-main" type="submit" :disabled="loading || form.confirmCode && codeLength !== form.confirmCode.length">
        <LoadingIndicator v-if="loading"/>
        <template v-else>Далее</template>
      </button>
    </div>
  </form>
</template>

<script>
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputComponent from "@/components/form/InputComponent";

export default {
  name: "ContinueRegistration",
  props: {
    codes: Object,
  },
  data() {
    return {
      showStep: false,
      codeLength: 4,
      phoneMessage: null,
      loading: false,
      error: null,
      codeError: null,
      timer: this.$store.state.config.code_timer,
      interval: null,
      form: {
        confirmCode: null,
        password: null,
      }
    }
  },
  // beforeCreate() {
  //   this.$store.dispatch('BEGIN_REGISTRATION', {
  //     data: { phoneNumber: this.$store.state.user.phoneNumber },
  //     // token: localStorage.getItem("auth-token")
  //   })
  //       // .then(({ data }) => {
  //       //   if (data && data.data.currentPhoneNumber) {
  //       //     if (this.$store.state.config.password_as_confirmation_code) {
  //       //       this.$emit("changeStep", this.codes.QUESTIONS_CODE)
  //       //     } else {
  //       //       this.$emit("changeStep", this.codes.PASSWORD_CHANGE_CODE)
  //       //     }
  //       //   } else if (data && data.data && data.data.confirmCodeLength && data.result && data.result.state === "Success") {
  //       //     this.codeLength = data.data.confirmCodeLength
  //       //     this.phoneMessage = "Введите код, отправленный в смс на номер: \n" +
  //       //         this.$options.filters.vMask(this.$store.state.user.phoneNumber, "+# (###) ###-##-##")
  //       //     this.countdown()
  //       //     this.showStep = true;
  //       //   } else if (data.result && data.result.state === "Error") {
  //       //     this.codeError = data.result.message
  //       //     this.timer = 0
  //       //     this.showStep = true;
  //       //   }
  //       // })
  // },
  computed: {
    vMaskStr() {
      return "#".repeat(this.codeLength)
    },
  },
  methods: {
    countdown() {
      clearInterval(this.interval)
      this.timer = this.$store.state.config.code_timer
      this.interval = setInterval(() => {
        if (this.timer <= 0) {
          clearInterval(this.interval)
          return
        }
        this.timer -= 1
      }, 1000)
    },
    resendCode() {
      this.$store.dispatch("SEND_CONFIRM_CODE", {
        data: { phoneNumber: this.$store.state.user.phoneNumber },
        token: localStorage.getItem("auth-token")
      })
          .then(({ data }) => {
            if (data.result && data.result.state === "Success") {
              this.timer = this.$store.state.config.code_timer
              this.countdown()
            } else if (data.result && data.result.state === "Error") {
              this.error = data.result.message
            }
          })
    },
    formSubmit() {
      if (!this.loading) {
        this.loading = true

        this.$store.dispatch("BEGIN_REGISTRATION", {
          login: this.$store.state.user.phoneNumber,
          password: this.form.confirmCode,
        })
            .then(({data}) => {
              console.log("data", data)
              let token = data && data.data && data.data.authToken ? data.data.authToken : null
              if (data.result && data.result.state === "Success" && token) {
                // сохраняем полученный токен
                this.$store.dispatch('setAuthToken', { token: token })
                // перенаправляем на стрину регистрации данных
                this.$emit("changeStep", this.codes.QUESTIONS_CODE)
                this.loading = false

              } else {
                this.loading = false
                if (data.result.message) {
                  this.error = data.result.message
                } else {
                  this.error = "Введены неправильные данные"
                }
              }
            })
            .catch(() => {
              this.loading = false
            });

      }
    }
  },
  components: {
    InputComponent,
    LoadingIndicator,
  }
}
</script>
