<template>
  <form @submit.prevent="formSubmit" class="form" novalidate spellcheck="false">
    <div class="form__item">
      <InputComponent
        v-model="form.password"
        title="Введите пароль"
        type="password"
      />
    </div>
    <div class="form__item">
      <InputComponent
        v-model="form.password_confirm"
        title="Повторите пароль"
        type="password"
      />
    </div>
    <div class="form__message">Минимальная длина пароля 6 символов</div>
    <div class="form__error" v-if="error">{{ error }}</div>
    <div
        v-if="passwordsEqualMessage"
        :style="{ color: passwordsEqualMessage.color }"
        class="form__message"
    >
      {{ passwordsEqualMessage.title }}
    </div>
    <div class="form__actions">
<!--      <button-->
<!--        type="button"-->
<!--        @click.prevent="$emit('changeStep', codes.BEGIN_REGISTRATION_CODE)"-->
<!--        class="btn-main"-->
<!--      >-->
<!--        Назад-->
<!--      </button>-->
      <button class="btn-main" type="submit" :disabled="loading || !passwordsEqual">
        <LoadingIndicator v-if="loading"/>
        <template v-else>Далее</template>
      </button>
    </div>
  </form>
</template>

<script>
import LoadingIndicator from "../../../components/LoadingIndicator";
import InputComponent from "@/components/form/InputComponent";

export default {
  name: "BeginRegistration",
  props: {
    codes: Object,
  },
  data() {
    return {
      loading: false,
      error: null,
      message: {
        1: {
          title: "Пароли совпадают",
          color: "#A4C65E"
        },
        2: {
          title: "Пароли не совпадают",
          color: "#FF0000"
        }
      },
      form: {
        password: null,
        password_confirm: null,
      }
    }
  },
  created() {
    if (this.$store.state.config.password_as_confirmation_code) {
      this.form.password = this.$store.state.user.code
      this.form.password_confirm = this.$store.state.user.code
      this.formSubmit()
      this.$emit("changeStep", this.codes.QUESTIONS_CODE)
    }
  },
  computed: {
    passwordsEqual() {
      return this.isValidPass(this.form.password) && this.isValidPass(this.form.password_confirm) && this.form.password === this.form.password_confirm
    },
    passwordsEqualMessage() {
      return this.passwordsEqual ?
          this.message['1'] :
            this.form.password &&
            this.form.password.length > 6 &&
            this.form.password_confirm &&
            this.form.password_confirm.length > 6 ?
                this.message['2']
              : null
    }
  },
  methods: {
    isValidPass(pass) {
      return pass && pass.length >= 6
    },
    sendConfirmCode() {
      this.$store.dispatch("SEND_CONFIRM_CODE", {
        data: { phoneNumber: this.$store.state.user.phoneNumber },
        token: localStorage.getItem("auth-token")
      })
    },
    formSubmit() {
      if (!this.loading && this.passwordsEqual) {
        this.loading = true

        this.$store.dispatch("PASSWORD_SET", {
          data: {
            password: this.form.password,
          },
          token: localStorage.getItem("auth-token")
        })
            .then(({ data }) => {
              // если введенный номер еще не зарегистрирован, то на него отправляется сообщение
              let token = data && data.data && data.data.access_token ? data.data.access_token : null
              if (data.result.state === "Success" && token) {
                // сохраняем полученный токен
                this.$store.dispatch('setAuthToken', {token: token})
                // переход на след шаг
                this.$emit("changeStep", this.codes.QUESTIONS_CODE)
              } else if (data.result.state === "Error") {
                this.error = data.result.message
              } else if (data.result.state === "ValidationError") {
                this.error = "Ошибка. Введите данные"
              }
              this.loading = false
            })
      }
    }
  },
  components: {
    InputComponent,
    LoadingIndicator,
  }
}
</script>
